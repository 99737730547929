import * as React from "react";

const useAnimationFrame = (callback, dependencies) => {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = React.useRef();
  const previousTimeRef = React.useRef();

  const animate = (time) => {
    if (typeof previousTimeRef.current !== "undefined") {
      const delta = time - previousTimeRef.current;
      callback({ time, delta });
    }
    previousTimeRef.current = time;
    requestRef.current = window.requestAnimationFrame(animate);
  };

  React.useEffect(() => {
    requestRef.current = window.requestAnimationFrame(animate);
    return () => {
      window.cancelAnimationFrame(requestRef.current);
    };
  }, dependencies); // Make sure the effect runs only once
};

export default useAnimationFrame;
